import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from '../../Constants';
import { getToken, getUserId } from '../../auth/auth.js';
import { toast } from 'react-toastify';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import { getWeek } from 'date-fns';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function TopBar({ open, toggleDrawer }) {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const userId = getUserId();
  const showSecretLink = [1, 2, 72].includes(Number(userId));

  const handleLogout = async () => {
    const token = getToken();

    const response = await fetch(`${BASE_URL}/api/logout`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })

    const responseBody = await response.json();
    console.log(responseBody);
    
    //for user
    localStorage.removeItem("token");
    localStorage.removeItem("userId");

    //for session
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("userId");

    navigate('/login');

    toast.success('You have been logged out', {
      autoClose: 2000,  // Auto close after dash seconds
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const getWeekNumber = (date) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    const yearStart = new Date(d.getFullYear(), 0, 1);
    const weekNumber = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    return weekNumber;
  };

  const getMonthName = (date) => {
    return new Date(date).toLocaleString('default', { month: 'long' });
  };

  const getWeekDates = (date) => {
    const d = new Date(date);
    const day = d.getDay();
    const diff = d.getDate() - day;
    const weekStart = new Date(d.setDate(diff));
    const weekEnd = new Date(d.setDate(diff + 6));
    return { weekStart, weekEnd };
  };

  const getCurrentWeekInfo = () => {
    const now = new Date();
    const { weekStart, weekEnd } = getWeekDates(now);
    const weekNumber = getWeekNumber(now);
    const monthName = getMonthName(now);
    const year = now.getFullYear();

    return `Week ${weekNumber} - ${monthName} ${year} (${weekStart.toLocaleDateString('en-US', { 
      weekday: 'long',
      month: 'numeric',
      day: 'numeric'
    })} - ${weekEnd.toLocaleDateString('en-US', { 
      weekday: 'long',
      month: 'numeric',
      day: 'numeric'
    })})`;
  };

  let title;
  if (location.pathname.startsWith("/yieldedit")) {
    title = "Edit Yield";
  } else {
    switch (location.pathname) {
      case "/":
        title = "Dashboard";
        break;
      case "/addshipment":
        title = "Add Shipment";
        break;
      case "/shipmentview":
        title = "Shipments list";
        break;
      case "/addreceived":
        title = "Add Received";
        break;
      case "/receivedview":
        title = "Received blocks list";
        break;
      case "/addcut":
        title = "Add Cut";
        break;
      case "/cutview":
        title = "Cut blocks list";
        break;
      case "/addyield":
        title = "Add Yield";
        break;
      case "/yieldview":
        title = "Yield list";
        break;
      case "/yieldsuccess":
        title = "Add Yield";
        break;
      case "/userprofile":
        title = "User Profile";
        break;
      case "/selectfarm":
        title = "Please select your farm to continue";
        break;
      case "/manageusers":
        title = "Manage Users";
        break;
      case "/manageregions":
        title = "Manage Regions";
        break;
      case "/managefarms":
        title = "Manage Farms";
        break;
      case "/managestrains":
        title = "Manage Strains";
        break;
      case "/adduser":
        title = "Add new user";
        break;
      case "/blockorder":
        title = "Order new blocks";
        break;
      case "/blockorderlist":
        title = "Block Order List";
        break;
      case "/inoculationschedule":
        title = "Inoculation Schedule";
        break;
      case "/farm/Ottawa":
        title = "Ottawa Farm Dashboard";
        break;
      case "/chatai":
        title = "HearteeAI ChatBot";
        break;
      case "/tossedview":
        title = "All tossed blocks";
        break;
      case "/sdwview":
        title = "Fresh Samples/Donated/Waste";
        break;      
      case "/addsdw":
        title = "Add Fresh Samples/Donated/Waste";
        break;
      case "/managecustomers":
        title = "Manage Customers";
        break;
      case "/customerorder":
        title = "New customer order";
        break;
      case "/lmshome":
        title = "LMS Home";
        break;
      case "/lmsactivitylog":
        title = "Activity log";
        break;
      case "/lmsnewcourse":
        title = "Create new course";
        break;
    }
  }
  

  return (
    <AppBar position="absolute" open={open} theme={theme}>
      <Toolbar 
        sx={{
          pr: '24px',
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>

        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          {title}
          {location.pathname === '/customerorders' && (
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              sx={{ 
                ml: 2,
                opacity: 0.9,
                fontWeight: 'normal',
                display: 'inline-block'
              }}
            >
              {getCurrentWeekInfo()}
            </Typography>
          )}
        </Typography>

        <Typography>
          <Link to="/addyield" style={{ textDecoration: 'none', color: 'inherit' }}>+YIELD</Link>
        </Typography>
        
        <Link to="/userprofile" style={{ color: 'inherit', textDecoration: 'none' }}>
          <IconButton color="inherit">
            <PersonIcon/>
          </IconButton>
        </Link>

        <IconButton color="inherit" onClick={handleLogout}>
          <LogoutIcon/>
        </IconButton>
        
      </Toolbar>
    </AppBar>
  );
}
