import React, { useState, useEffect, useRef } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider, Box, Grid, FormControl, Button, FormLabel, TextField, RadioGroup, Radio, FormControlLabel, MenuItem, Select, FormHelperText, OutlinedInput, InputAdornment, Accordion, AccordionSummary, InputLabel, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';
import { BASE_URL } from '../../Constants';
import { getToken } from '../../auth/auth.js';

const mdTheme = createTheme();

const ContentWrapper = styled('div')({});

function SDWGroup({ 
    index, 
    firstStrain, 
    state, 
    onChange, 
    highlight, 
    isOpen,
    sdw_image, 
    disableSDWTypeTwo = false,
    weightError,
    setWeightError
}) {

    const { num_of_blocks, numOfBlocks = num_of_blocks || 1, strain = firstStrain, weight = 0 } = state;
    const [localNumOfBlocks, setLocalNumOfBlocks] = useState(state.num_of_blocks || state.numOfBlocks || 1);
    const numOfBlocksRef = useRef();
    const [localWeight, setLocalWeight] = React.useState(state.weight || '');
    const weightRef = useRef();
    const [strains, setStrains] = useState([]);
    const [sdwType, setSDWType] = useState(state.sdwType || 'Samples');
    const [sdwTypeTwo, setSDWTypeTwo] = useState(state.sdwTypeTwo || 'blocks');
    const [reason, setReason] = useState(state.reason || 'Trich Contam');
    const [description, setDescription] = useState(state.description || '');
    const [selectedImage, setSelectedImage] = useState(sdw_image || state.sdwImage || null);
    const [isAccordionOpen, setAccordionOpen] = useState(true);
    const [isHighlighted, setIsHighlighted] = useState(false);

    const token = getToken();

    useEffect(() => {
        setLocalNumOfBlocks(state.num_of_blocks || state.numOfBlocks || numOfBlocks);
    }, [state.num_of_blocks, state.numOfBlocks, numOfBlocks]);

    //strain mappings
    useEffect(() => {
        const fetchStrains = async () => {
            try {
            const response = await fetch(`${BASE_URL}/api/mappings`, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
                }
            });
        
            const data = await response.json();
            setStrains(data.strain);
            } catch (error) {
            console.error('Failed to fetch strains', error);
            }
        };
        
        fetchStrains();
    }, []);  

    const handleChangeNumOfBlocks = (event) => {
        setLocalNumOfBlocks(event.target.value);
    };

    const handleBlurNumOfBlocks = () => {
        onChange(index, { numOfBlocks: localNumOfBlocks, strain, weight });
    };

    const handleReasonChange = (event) => {
        const newReason = event.target.value;
        setReason(newReason);
        onChange(index, { ...state, reason: newReason }); // Update parent state
      };

    const handleChangeStrain = (event) => {
        const newStrain = event.target.value;
        onChange(index, { numOfBlocks: localNumOfBlocks, strain: newStrain, weight }); // pass new state back to parent
    }  

    const handleChangeWeight = (event) => {
        const value = event.target.value;
        setLocalWeight(value);
        setWeightError(value === "" || parseFloat(value) <= 0);
    };
    
    const handleBlurWeight = () => {
        if (localWeight === "" || parseFloat(localWeight) <= 0) {
            setWeightError(true);
        } else {
            setWeightError(false);
            onChange(index, { ...state, weight: localWeight });
        }
    };

    const handleSDWTypeChange = (event) => {
        const newSDWType = event.target.value;
        setSDWType(newSDWType);
        onChange(index, { ...state, sdwType: newSDWType }); // Update parent state
    };

    const handleSDWTypeTwoChange = (event) => {
        const newSDWTypeTwo = event.target.value;
        setSDWTypeTwo(newSDWTypeTwo);
        // Automatically set sdwType to "Samples" if "fresh" is selected
        if (newSDWTypeTwo === 'fresh') {
            setSDWType('Samples');
            onChange(index, { ...state, sdwTypeTwo: newSDWTypeTwo, sdwType: 'Samples' });
        } else {
            onChange(index, { ...state, sdwTypeTwo: newSDWTypeTwo });
        }
    };
    
    const handleDescriptionChange = (event) => {
        const newDescription = event.target.value;
        setDescription(newDescription);
        onChange(index, { ...state, description: newDescription }); // Update parent state
    };
    
    const handleImageChange = (event, index) => {
        console.log('handleImageChange triggered');
        if (event.target.files && event.target.files[0]) {
          const file = event.target.files[0];
        //   setSelectedImage(URL.createObjectURL(file)); // For previewing the image
          setSelectedImage(file);
          onChange(index, { ...state, sdwImage: file }); // update parent state
        }
    };
      
    useEffect(() => {
      setLocalWeight(localWeight);
    }, [localWeight]);

    useEffect(() => {
        if (highlight) {
            setIsHighlighted(true);
            setTimeout(() => {
                setIsHighlighted(false);
            }, 250);
        }
    }, [highlight]);

    useEffect(() => {
        setAccordionOpen(isOpen);
    }, [isOpen]);

    // Select the content of the numOfBlocks input when focused
    useEffect(() => {
        if (numOfBlocksRef.current) {
            numOfBlocksRef.current.addEventListener('focus', () => {
                numOfBlocksRef.current.select();
            });
        }
    }, []);

    // Select the content of the weight input when focused
    useEffect(() => {
        if (weightRef.current) {
            weightRef.current.addEventListener('focus', () => {
                weightRef.current.select();
            });
        }
    }, []);

    return (
        <ThemeProvider theme={mdTheme}>

            <CssBaseline />
                <ContentWrapper highlighted={highlight}>
                    <Accordion expanded={isAccordionOpen} onChange={() => setAccordionOpen(!isAccordionOpen)}
                      sx={{ 
                        width: '100%',
                        maxWidth: '400px',
                        margin: 'auto',
                        backgroundColor: isHighlighted ? '#d5ebd0' : 'default color',
                        transition: 'background-color 1s ease-out'
                      }}
                    >

                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>
                                {strain}, blocks: {numOfBlocks},
                                {sdwTypeTwo === 'blocks' && ` Type: Tossed waste, Number of blocks: ${localNumOfBlocks}, Reason: ${reason}`}
                                {sdwTypeTwo === 'fresh' && ` Type: Fresh waste, Weight: ${localWeight || '0'} lbs, Category: ${sdwType}`}
                            </Typography> 
                        </AccordionSummary>

                        <AccordionDetails>

                            {/* Strain */}
                            <Box margin="1em">
                                <FormControl fullWidth variant="outlined">
                                    <FormLabel component="legend">Strain</FormLabel>
                                    <Select
                                        value={strain}
                                        onChange={handleChangeStrain}
                                        defaultValue={firstStrain}
                                    >
                                        {strains.map((strainObj, index) => (
                                            <MenuItem key={index} value={strainObj.strain_name}>{strainObj.strain_name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>

                            {/* Blocks or Fresh */}
                            {!disableSDWTypeTwo && (
                                <Box margin="1em">
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Blocks or Fresh</FormLabel>
                                        <RadioGroup row aria-label="sdwtypetwo" name="sdwtypetwo" value={sdwTypeTwo} onChange={handleSDWTypeTwoChange}>
                                            <FormControlLabel value="blocks" control={<Radio />} label="Blocks" />
                                            <FormControlLabel value="fresh" control={<Radio />} label="Fresh" />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                            )}

                            {/* Conditional Num of blocks */}
                            {sdwTypeTwo === 'blocks' && (
                                <>
                                    <Box margin="1em">
                                        <TextField
                                            type="number"
                                            id="numOfBlocks"
                                            value={localNumOfBlocks}
                                            onChange={handleChangeNumOfBlocks}
                                            label="Number of blocks"
                                            variant="outlined"
                                            fullWidth
                                            keyboardtype="numeric" 
                                            onBlur={handleBlurNumOfBlocks}
                                            inputRef={numOfBlocksRef}
                                        />
                                    </Box>
                                </>
                            )}

                            {/* Conditional Reason */}
                            {sdwTypeTwo === 'blocks' && (
                                <Box margin='1em' sx={{ mt: 2 }}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Reason</FormLabel>
                                        <RadioGroup
                                            aria-label="reason"
                                            name="reason"
                                            value={reason}
                                            onChange={handleReasonChange}
                                        >
                                            <Grid container>
                                                <FormControlLabel value="Dud block" control={<Radio />} label="Dud block" />
                                                <FormControlLabel value="Contam: Trych" control={<Radio />} label="Contam: Trych" />
                                                <FormControlLabel value="Contam: Neuro" control={<Radio />} label="Contam: Neuro" />
                                                <FormControlLabel value="Contam: Other" control={<Radio />} label="Contam: Other" />
                                                <FormControlLabel value="Spoilage: Unviable harvest" control={<Radio />} label="Spoilage: Unviable harvest" />
                                            </Grid>
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                            )}

                            {/* Conditional Weight */}
                            {sdwTypeTwo === 'fresh' && (
                                <Box margin='1em'>
                                    <FormControl fullWidth variant="outlined" error={weightError}>
                                        <InputLabel htmlFor="weight">Weight</InputLabel>
                                        <OutlinedInput
                                            id="weight"
                                            value={localWeight}
                                            onChange={handleChangeWeight}
                                            onBlur={handleBlurWeight}
                                            endAdornment={<InputAdornment position="end">lbs</InputAdornment>}
                                            aria-describedby="weight-error-text"
                                            inputProps={{
                                                'aria-label': 'weight',
                                                min: 0.01,
                                                step: 0.01,
                                                type: 'number'
                                            }}
                                            inputRef={weightRef}
                                        />
                                        {weightError && (
                                            <FormHelperText id="weight-error-text" error>
                                                Please enter a valid weight
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Box>
                            )}

                            {/*  Conditional Category */}
                            {sdwTypeTwo === 'fresh' && (
                                <Box margin="1em">
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Category</FormLabel>
                                        <RadioGroup row aria-label="sdwtype" name="sdwtype" value={sdwType} onChange={handleSDWTypeChange}>
                                            <FormControlLabel value="Samples" control={<Radio />} label="Samples" />
                                            <FormControlLabel value="Donated" control={<Radio />} label="Donated" />
                                            <FormControlLabel value="Transfered" control={<Radio />} label="Transfered" />
                                            <FormControlLabel value="Trim" control={<Radio />} label="Trim" />
                                            <FormControlLabel value="Spoilage: Sitting too long" control={<Radio />} label="Spoilage: Sitting too long" />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                            )}

                            {/* Description text box */}
                            <Box margin="1em">
                                <TextField
                                    id="description"
                                    label="Description"
                                    multiline
                                    rows={4}
                                    value={description}
                                    onChange={handleDescriptionChange}
                                    variant="outlined"
                                    fullWidth
                                />
                            </Box>

                            {/* Image upload input */}
                            <Box margin="1em">
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(event) => handleImageChange(event, index)}
                                    id={`image-upload-${index}`} 
                                    style={{ display: 'none' }} // Hides the default input
                                />
                                <label htmlFor={`image-upload-${index}`}>
                                    <Button variant="contained" color="primary" component="span">
                                        Upload Image
                                    </Button>
                                </label>
                                {selectedImage && (
                                    <Box margin="1em" style={{ maxWidth: '200px', maxHeight: '200px', overflow: 'hidden' }}>
                                        <img 
                                            // src={selectedImage} 
                                            src={selectedImage instanceof File ? URL.createObjectURL(selectedImage) : selectedImage} 
                                            alt="Uploaded" 
                                            style={{ maxWidth: '100%', height: 'auto' }} />
                                    </Box>
                                )}
                            </Box>

                        </AccordionDetails>

                    </Accordion>
                </ContentWrapper>
            
        </ThemeProvider>
    );
}

export default SDWGroup;