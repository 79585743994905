import React from 'react';
import Paper from '@mui/material/Paper'; 
import ReactApexChart from 'react-apexcharts';
import { BASE_URL } from '../../Constants';
import { getToken, getFarmId } from '../../auth/auth.js';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';

function InventoryBarChart() {

  const token = getToken();
  const farmId = getFarmId();

  const [farmData, setFarmData] = React.useState([]);
  const [selectedFarm, setSelectedFarm] = React.useState(''); 
  const [selectedTime, setSelectedTime] = React.useState('today');
  const [customStartDate, setCustomStartDate] = React.useState(null);
  const [customEndDate, setCustomEndDate] = React.useState(null);

  const [chartData, setChartData] = React.useState({
    categories: [],
    series: [],
  });

    const fetchBarChartData = async (farm, time, start, end) => {
      try {
        let farmQueryParam = farm ? `?farmName=${encodeURIComponent(farm)}` : '?'
        let timeQueryParam = '';
        
        if (time === 'custom' && start && end) {
          const formattedStartDate = dayjs(start).format('YYYY-MM-DD');
          const formattedEndDate = dayjs(end).format('YYYY-MM-DD');
          timeQueryParam = `&timeFilter=${time}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        } else if (time) {
          timeQueryParam = `&timeFilter=${time}`;
        }

        const response = await fetch(`${BASE_URL}/api/readinventorybarchartdata${farmQueryParam}${timeQueryParam}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        const jsonData = await response.json();
        console.log('API Response:', jsonData); // debug

        // Ensure we're working with an array
        const dataArray = Array.isArray(jsonData) ? jsonData : jsonData.data || [];

        // Aggregate data by strain, summing total weights across all farms.
        const strainWeightMap = dataArray.reduce((acc, { strain_name, total_weight }) => {
          if (!acc[strain_name]) {
            acc[strain_name] = 0;
          }
          acc[strain_name] += parseFloat(total_weight) || 0; // Convert to number and handle null/undefined
          return acc;
        }, {});

        const categories = Object.keys(strainWeightMap);
        const seriesData = Object.values(strainWeightMap).map(weight => Number(weight.toFixed(2)));

        setChartData({
          categories,
          series: [{
            name: "Total Weight",
            data: seriesData,
          }],
        });
      } catch (error) {
        console.error('Error fetching bar chart data:', error);
        // Set empty data on error
        setChartData({
          categories: [],
          series: [{
            name: "Total Weight",
            data: [],
          }],
        });
      }
    };

    const fetchFarms = () => { 
      fetch(`${BASE_URL}/api/readfarms`, { 
        method: 'GET', 
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json(); // Directly parse as JSON, instead of using response.text()
      })
      .then(data => {
        setFarmData(data);
        // Find the farm_name corresponding to the user's farm_id
        const userFarm = data.find(farm => farm.farm_id === farmId);
        setSelectedFarm(userFarm ? userFarm.farm_name : 'all'); // Default to 'all' if no match
      })
      .catch(error => {
        console.error('Error:', error);
      });
    }

    React.useEffect(() => {
      const initializeData = async () => {
        try {
          const [farmsResponse] = await Promise.all([
            fetch(`${BASE_URL}/api/readfarms`, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
            })
          ]);

          const farmsData = await farmsResponse.json();
          setFarmData(farmsData);
          
          // Set initial farm and fetch chart data
          const userFarm = farmsData.find(farm => farm.farm_id === farmId);
          const initialFarm = userFarm ? userFarm.farm_name : 'all';
          setSelectedFarm(initialFarm);
          
          // Initial chart data fetch
          await fetchBarChartData(initialFarm, 'today');
        } catch (error) {
          console.error('Error initializing data:', error);
        }
      };

      initializeData();
    }, []); // Run once on mount

    // Debounce the chart data fetching
    const debouncedFetchData = React.useCallback(
      debounce((farm, time, start, end) => {
        fetchBarChartData(farm, time, start, end);
      }, 300),
      []
    );

    // Update chart when filters change
    React.useEffect(() => {
      debouncedFetchData(selectedFarm, selectedTime, customStartDate, customEndDate);
    }, [selectedFarm, selectedTime, customStartDate, customEndDate]);

  const chartOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false, // hide the toolbar 
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: chartData.categories,
    },
    yaxis: {
      title: {
        text: 'Product (lbs)',
      },
      labels: {
        formatter: function (val) {
          return val.toFixed(2);
        }
      }
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `${val.toFixed(2)} lbs`;
        }
      }
    },
  };

  const handleFarmChange = (event) => {
    setSelectedFarm(event.target.value);
  };

  const handleTimeFilterChange = (event) => {
    const newTimeFilter = event.target.value;
    setSelectedTime(newTimeFilter);
    
    if (newTimeFilter !== 'custom') {
      setCustomStartDate(null);
      setCustomEndDate(null);
    }
  };

  const isDateValid = (startDate, endDate) => {
    if (!startDate || !endDate) return true;
    return dayjs(endDate).isAfter(dayjs(startDate)) || dayjs(endDate).isSame(dayjs(startDate), 'day');
  };

  const renderDatePickers = () => {
    if (selectedTime !== 'custom') return null;

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box sx={{ 
          display: 'flex', 
          gap: 2,
          '& .MuiTextField-root': {  // Make date pickers same height
            '& .MuiOutlinedInput-root': {
              height: '56px'
            }
          }
        }}>
          <DatePicker
            label="Start Date"
            value={customStartDate}
            onChange={(newValue) => {
              setCustomStartDate(newValue);
            }}
            maxDate={customEndDate || dayjs()}
            slotProps={{
              textField: {
                size: "medium", // Changed from "small" to "medium"
                sx: {
                  '& .MuiInputLabel-root': {
                    background: '#fff',
                    padding: '0 8px',
                  },
                  width: '200px' // Consistent width for date pickers
                }
              }
            }}
          />
          <DatePicker
            label="End Date"
            value={customEndDate}
            onChange={(newValue) => {
              setCustomEndDate(newValue);
            }}
            minDate={customStartDate}
            maxDate={dayjs()}
            slotProps={{
              textField: {
                size: "medium", // Changed from "small" to "medium"
                sx: {
                  '& .MuiInputLabel-root': {
                    background: '#fff',
                    padding: '0 8px',
                  },
                  width: '200px' // Consistent width for date pickers
                }
              }
            }}
          />
        </Box>
      </LocalizationProvider>
    );
  };

  return (
    <div>
      <Paper sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: '500', whiteSpace: 'nowrap' }}>
          Yields
        </Typography>

        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 2
        }}>

          {/* Farm Filter Dropdown */}
          <FormControl sx={{ 
            minWidth: 140,
            '& .MuiInputLabel-root': {  // Fix for strikethrough labels
              background: '#fff',
              padding: '0 8px',
            },
            '& .MuiOutlinedInput-root': {  // Make all inputs same height
              height: '56px'  // Standard height for MUI inputs
            }
          }}>
            <InputLabel id="farm-select-label">Filter farm</InputLabel>
            <Select
              labelId="farm-select-label"
              id="farm-select"
              value={selectedFarm}
              label="Filter farm"
              onChange={handleFarmChange}
            >
              <MenuItem value="all">
                <em>All</em>
              </MenuItem>
              <MenuItem value="GTA">
                GTA
              </MenuItem>
              {farmData.map((farm, index) => (
                <MenuItem key={`${farm.farm_name}-${index}`} value={farm.farm_name}>{farm.farm_name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Time Filter Dropdown */}
          <FormControl sx={{ 
            minWidth: 140,
            '& .MuiInputLabel-root': {  // Fix for strikethrough labels
              background: '#fff',
              padding: '0 8px',
            },
            '& .MuiOutlinedInput-root': {  // Make all inputs same height
              height: '56px'  // Standard height for MUI inputs
            }
          }}>
            <InputLabel id="time-select-label">Filter time</InputLabel>
            <Select
              labelId="time-select-label"
              id="time-select"
              value={selectedTime}
              label="Filter time"
              onChange={handleTimeFilterChange}
            >
              <MenuItem value="all">
                <em>All Time</em>
              </MenuItem>
              <MenuItem value="today">Today</MenuItem>
              <MenuItem value="24">Last 24 hours</MenuItem>
              <MenuItem value="48">Last 48 hours</MenuItem>
              <MenuItem value="3">Last 3 days</MenuItem>
              <MenuItem value="7">Last 7 days</MenuItem>
              <MenuItem value="30">Last 30 days</MenuItem>
              <MenuItem value="month">Last month</MenuItem>
              <MenuItem value="custom">Custom Range</MenuItem>
            </Select>
          </FormControl>

          {renderDatePickers()}

        </Box>
      </Paper>

      <Paper sx={{ p: 1, }}>
        <ReactApexChart
          options={chartOptions}
          series={chartData.series}
          height={350}
          type="bar"
        />
      </Paper>

    </div>
  );
}

export default InventoryBarChart;