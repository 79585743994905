import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Fab,
  Autocomplete,
  Stack,
  Divider,
  Tab,
  Tabs
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import TodayIcon from '@mui/icons-material/Today';
import Checkbox from '@mui/material/Checkbox';
import { getToken } from '../../auth/auth';
import { BASE_URL } from '../../Constants';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

function CustomerOrders() {
  const [inventoryData, setInventoryData] = useState([]);
  const [farms, setFarms] = useState([]);
  const [selectedFarm, setSelectedFarm] = useState('');
  const [strains, setStrains] = useState(new Set());
  const [processedData, setProcessedData] = useState([]);
  const [editingRow, setEditingRow] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [hasChanges, setHasChanges] = useState(false);
  const [openOrderDialog, setOpenOrderDialog] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedStrain, setSelectedStrain] = useState(null);
  const [orderWeight, setOrderWeight] = useState('');
  const [orderDate, setOrderDate] = useState(new Date());
  const [fulfillmentDate, setFulfillmentDate] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [orders, setOrders] = useState([]);
  const [editingOrder, setEditingOrder] = useState(null);
  const [editedOrder, setEditedOrder] = useState({});
  const [orderItems, setOrderItems] = useState([{ strain_id: '', weight: '' }]);
  const [editedOrderItems, setEditedOrderItems] = useState([]);
  const [weeklyOrders, setWeeklyOrders] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [openShrinkageDialog, setOpenShrinkageDialog] = useState(false);
  const [shrinkageData, setShrinkageData] = useState([]);
  const token = getToken();
  const [editMode, setEditMode] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [completedItems, setCompletedItems] = useState({});

  useEffect(() => {
    fetchFarms().then(() => {
      const ottawaFarm = farms.find(farm => farm.farm_name.toLowerCase() === 'ottawa');
      if (ottawaFarm) {
        setSelectedFarm(ottawaFarm.farm_id);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedFarm) {
      fetchInventoryData();
    }
  }, [selectedFarm]);

  useEffect(() => {
    // Process inventory data to get unique strains and organize data by farm
    const uniqueStrains = new Set();
    const farmData = {};

    inventoryData.forEach(item => {
      uniqueStrains.add({
        strain_id: item.strain_id,
        strain_name: item.strain_name
      });
      
      if (!farmData[item.farm_id]) {
        farmData[item.farm_id] = {
          farm_id: item.farm_id,
          farm_name: item.farm_name,
          strains: {}
        };
      }
      
      farmData[item.farm_id].strains[item.strain_name] = item.current_weight;
    });

    setStrains(uniqueStrains);
    setProcessedData(Object.values(farmData));
  }, [inventoryData]);

  useEffect(() => {
    fetchCustomers();
    fetchOrders();
  }, []);

  useEffect(() => {
    fetchOrders();
  }, [currentDate]);

  const fetchFarms = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/readfarms`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setFarms(data);
      
      const ottawaFarm = data.find(farm => farm.farm_name.toLowerCase() === 'ottawa');
      if (ottawaFarm) {
        setSelectedFarm(ottawaFarm.farm_id);
      }
    } catch (error) {
      console.error('Error fetching farms:', error);
    }
  };

  const fetchInventoryData = async () => {
    try {
      let url = `${BASE_URL}/api/inventory`;
      if (selectedFarm) {
        url += `?farmId=${selectedFarm}`;
      }

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      const data = await response.json();
      setInventoryData(data);
    } catch (error) {
      console.error('Error fetching inventory data:', error);
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/readcustomers`, {
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) throw new Error('Failed to fetch customers');
      const data = await response.json();
      setCustomers(data);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  };

  const fetchOrders = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/orders?date=${currentDate.toISOString()}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) throw new Error('Failed to fetch orders');
      const data = await response.json();
      setWeeklyOrders(data);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const handleFarmChange = (event) => {
    setSelectedFarm(event.target.value);
  };

  const handleEdit = (farmId) => {
    setEditingRow(farmId);
    const farmData = processedData.find(row => row.farm_id === farmId);
    
    // Create a mapping of strain_id to weight from the current data
    const initialWeights = {};
    Array.from(strains).forEach(strain => {
      initialWeights[strain.strain_id] = farmData.strains[strain.strain_name] || 0;
    });
    
    setEditedData(initialWeights);
    setHasChanges(false);
  };

  const handleCancelEdit = () => {
    setEditingRow(null);
    setEditedData({});
    setHasChanges(false);
  };

  const handleWeightChange = (strain, value) => {
    // Convert value to number, default to 0 if empty
    const numericValue = value === '' ? 0 : Number(value);
    
    // Only update if it's a valid number
    if (!isNaN(numericValue)) {
      setEditedData(prev => ({
        ...prev,
        [strain.strain_id]: numericValue
      }));
      setHasChanges(true);
    }
  };

  const handleSave = async (farmId) => {
    try {
      // Only include strains that have been edited
      const updates = Array.from(strains)
        .filter(strain => strain.strain_id in editedData)
        .map(strain => ({
          farm_id: Number(farmId),
          strain_id: Number(strain.strain_id),
          current_weight: Number(editedData[strain.strain_id])
        }));

      // Don't send request if no updates
      if (updates.length === 0) {
        setEditingRow(null);
        setEditedData({});
        setHasChanges(false);
        return;
      }

      const response = await fetch(`${BASE_URL}/api/inventory/update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ updates })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update inventory');
      }

      await fetchInventoryData();
      setEditingRow(null);
      setEditedData({});
      setHasChanges(false);
    } catch (error) {
      console.error('Error updating inventory:', error);
      // You might want to show this error to the user through a notification system
      throw error; // Re-throw to be handled by the caller if needed
    }
  };

  const handleEditOrder = (order) => {
    setEditMode(true);
    
    // Set customer
    const customer = customers.find(c => c.customer_id === order.customer_id);
    setSelectedCustomer(customer);

    // Set farm
    setSelectedFarm(order.farm_id);

    // Set only fulfillment date
    setFulfillmentDate(order.fulfillment_date ? new Date(order.fulfillment_date) : null);

    // Parse items if they're in string format
    const items = typeof order.items === 'string' ? JSON.parse(order.items) : order.items;
    
    // Set order items
    setOrderItems(items.map(item => ({
      strain_id: item.strain_id.toString(),
      weight: item.weight.toString()
    })));

    setSelectedOrder(order);
    setOpenOrderDialog(true);
  };

  const handleCreateOrder = async () => {
    try {
      const formatDate = (date) => {
        if (!date) return null;
        return date.toISOString().split('T')[0];
      };

      const orderData = {
        customer_id: selectedCustomer.customer_id,
        farm_id: selectedFarm,
        order_date: new Date().toISOString().split('T')[0], // Use current date
        fulfillment_date: formatDate(fulfillmentDate),
        order_items: orderItems.map(item => ({
          strain_id: parseInt(item.strain_id),
          weight: parseFloat(item.weight)
        }))
      };

      const url = editMode 
        ? `${BASE_URL}/api/orders/${selectedOrder.order_id}`
        : `${BASE_URL}/api/orders`;

      const method = editMode ? 'PUT' : 'POST';

      console.log(`${method}ing order:`, orderData);

      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(orderData)
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to save order');
      }

      await fetchOrders();
      handleCloseOrderDialog();
    } catch (error) {
      console.error('Error saving order:', error);
      alert(error.message);
    }
  };

  const handleCloseOrderDialog = () => {
    setOpenOrderDialog(false);
    setSelectedCustomer(null);
    setSelectedFarm(null);
    setOrderItems([{ strain_id: '', weight: '' }]);
    setFulfillmentDate(null);
    setEditMode(false); // Reset edit mode
    setSelectedOrder(null); // Reset selected order
  };

  const handleDeleteOrder = async (orderId) => {
    if (!window.confirm('Are you sure you want to delete this order?')) {
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/api/orders/${orderId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to delete order');
      }

      // Refresh orders and inventory data
      await Promise.all([fetchOrders(), fetchInventoryData()]);
    } catch (error) {
      console.error('Error deleting order:', error);
      alert('Failed to delete order: ' + error.message);
    }
  };

  const handleAddOrderItem = () => {
    setOrderItems([...orderItems, { strain_id: '', weight: '' }]);
  };

  const handleRemoveOrderItem = (index) => {
    if (orderItems.length > 1) {
      setOrderItems(orderItems.filter((_, i) => i !== index));
    }
  };

  const handleOrderItemChange = (index, field, value) => {
    const newItems = [...orderItems];
    newItems[index][field] = value;
    setOrderItems(newItems);
  };

  const handleAddEditedOrderItem = () => {
    setEditedOrderItems([...editedOrderItems, { strain_id: '', weight: '' }]);
  };

  const handleRemoveEditedOrderItem = (index) => {
    if (editedOrderItems.length > 1) {
      setEditedOrderItems(editedOrderItems.filter((_, i) => i !== index));
    }
  };

  const handleEditedOrderItemChange = (index, field, value) => {
    const newItems = [...editedOrderItems];
    newItems[index][field] = value;
    setEditedOrderItems(newItems);
  };

  // Helper function to get week number
  const getWeekNumber = (date) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    const yearStart = new Date(d.getFullYear(), 0, 1);
    const weekNumber = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    return weekNumber;
  };

  // Helper function to get month name
  const getMonthName = (date) => {
    return new Date(date).toLocaleString('default', { month: 'long' });
  };

  // Get week start and end dates
  const getWeekDates = (date) => {
    const d = new Date(date);
    const day = d.getDay();
    const diff = d.getDate() - day;
    const weekStart = new Date(d.setDate(diff));
    const weekEnd = new Date(d.setDate(diff + 6));
    return { weekStart, weekEnd };
  };

  // Update the week display in the table header section
  const formatDateRange = (date) => {
    const { weekStart, weekEnd } = getWeekDates(date);
    const weekNumber = getWeekNumber(date);
    const monthName = getMonthName(date);
    const year = date.getFullYear();

    return `Week ${weekNumber} - ${monthName} ${year} (${weekStart.toLocaleDateString('en-US', { 
      weekday: 'long',
      month: 'numeric',
      day: 'numeric'
    })} - ${weekEnd.toLocaleDateString('en-US', { 
      weekday: 'long',
      month: 'numeric',
      day: 'numeric'
    })})`;
  };

  const handleOpenShrinkageDialog = () => {
    // Make sure we have the selected farm
    if (!selectedFarm) {
      alert('Please select a farm first');
      return;
    }

    // Initialize shrinkage data from current inventory for all strains
    const shrinkageItems = Array.from(strains)
      .map(strain => {
        const currentWeight = processedData
          .find(row => row.farm_id === selectedFarm)
          ?.strains[strain.strain_name] || 0;
        
        return {
          strain_id: strain.strain_id,
          strain_name: strain.strain_name,
          current_weight: parseFloat(currentWeight).toFixed(2),
          new_weight: ''
        };
      });

    console.log('Initialized shrinkage data:', shrinkageItems);
    setShrinkageData(shrinkageItems);
    setOpenShrinkageDialog(true);
  };

  const handleCloseShrinkageDialog = () => {
    setOpenShrinkageDialog(false);
    setShrinkageData([]);
  };

  const handleShrinkageWeightChange = (strainId, value) => {
    setShrinkageData(prev => prev.map(item => {
      if (item.strain_id === strainId) {
        // Ensure the value is a valid number
        const newWeight = value === '' ? '' : parseFloat(value);
        return { ...item, new_weight: newWeight };
      }
      return item;
    }));
  };

  const handleSaveShrinkage = async () => {
    try {
      // Add logging to see the raw data
      console.log('Raw shrinkage data:', shrinkageData);

      const updates = shrinkageData
        .filter(item => {
          const currentWeight = parseFloat(item.current_weight);
          const newWeight = parseFloat(item.new_weight);
          
          console.log('Checking item:', {
            strain: item.strain_name,
            currentWeight,
            newWeight,
            isValid: item.new_weight !== '' && newWeight < currentWeight
          });
          
          return item.new_weight !== '' && newWeight < currentWeight;
        })
        .map(item => ({
          farm_id: Number(selectedFarm),
          strain_id: Number(item.strain_id),
          current_weight: parseFloat(item.current_weight),
          new_weight: parseFloat(item.new_weight)
        }));

      console.log('Filtered updates:', updates);

      if (updates.length === 0) {
        console.log('No valid updates to send');
        handleCloseShrinkageDialog();
        return;
      }

      const response = await fetch(`${BASE_URL}/api/inventory/shrinkage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ updates })
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Server response:', errorData);
        throw new Error(errorData.message || 'Failed to record shrinkage');
      }

      await fetchInventoryData();
      handleCloseShrinkageDialog();
    } catch (error) {
      console.error('Error recording shrinkage:', error);
      alert('Failed to record shrinkage: ' + error.message);
    }
  };

  // Add this helper function at the top with other functions
  const formatDateWithDay = (dateString) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      weekday: 'long', // 'short' gives "Mon", "Tue", etc.
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    });
  };

  const handleItemCompletion = async (orderId, itemIndex, checked) => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/orders/items/${orderId}/${itemIndex}/toggle-completion`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ isCompleted: checked ? 1 : 0 })
        }
      );

      if (!response.ok) {
        throw new Error('Failed to update item completion status');
      }

      // Refresh orders after updating
      fetchOrders();
    } catch (error) {
      console.error('Error updating item completion:', error);
    }
  };

  const goToPreviousWeek = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() - 7);
    setCurrentDate(newDate);
  };

  const goToNextWeek = () => {
    const newDate = new Date(currentDate);
    newDate.setDate(currentDate.getDate() + 7);
    setCurrentDate(newDate);
  };

  const goToCurrentWeek = () => {
    setCurrentDate(new Date());
  };

  return (
    <Box sx={{ p: 3, mt: 8 }}>
      <Stack spacing={4}>
        {/* Inventory Section */}
        <Box>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
              <TableContainer 
                component={Paper} 
                sx={{ 
                  maxWidth: 'fit-content',
                  '& .MuiTable-root': {
                    borderSpacing: '0px',
                    borderCollapse: 'separate'
                  }
                }}
              >
                <Table 
                  aria-label="inventory table" 
                  size="small" 
                  sx={{ 
                    '& .MuiTableCell-root': {
                      padding: '8px 8px',
                      borderSpacing: 0,
                      width: 'auto',
                      minWidth: '80px',
                      borderRight: '1px solid rgba(224, 224, 224, 0.4)',
                      '&:last-child': {
                        borderRight: 'none'
                      }
                    },
                    '& .MuiTableCell-head': {
                      backgroundColor: '#fff'
                    },
                    '& .MuiInputBase-input': {
                      padding: '4px 8px',
                      height: '20px'
                    },
                    '& .MuiOutlinedInput-root': {
                      height: '28px'
                    }
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ minWidth: '160px' }}>
                        <FormControl fullWidth size="small">
                          <InputLabel>Farm</InputLabel>
                          <Select
                            value={selectedFarm}
                            label="Farm"
                            onChange={handleFarmChange}
                          >
                           
                            {farms.map((farm) => (
                              <MenuItem key={farm.farm_id} value={farm.farm_id}>
                                {farm.farm_name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      {Array.from(strains).map(strain => (
                        <TableCell 
                          key={strain.strain_id} 
                          align="right" 
                          sx={{ 
                            fontWeight: 'bold',
                            minWidth: '80px'
                          }}
                        >
                          {strain.strain_name}
                        </TableCell>
                      ))}
                      <TableCell sx={{ width: '50px' }} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {processedData.map((row) => (
                      <TableRow 
                        key={row.farm_id}
                        sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f5f5f5' } }}
                      >
                        <TableCell>{row.farm_name}</TableCell>
                        {Array.from(strains).map(strain => (
                          <TableCell 
                            key={strain.strain_id} 
                            align="right"
                            sx={{ 
                              padding: '4px 8px',
                              height: '32px'
                            }}
                          >
                            {editingRow === row.farm_id ? (
                              <TextField
                                size="small"
                                type="number"
                                value={editedData[strain.strain_id] || ''}
                                onChange={(e) => handleWeightChange(strain, e.target.value)}
                                inputProps={{ 
                                  style: { 
                                    textAlign: 'right',
                                    padding: '4px',
                                    height: '20px'
                                  },
                                  min: 0,
                                  step: 0.01
                                }}
                                sx={{ 
                                  width: '70px',
                                  '& .MuiOutlinedInput-root': {
                                    height: '28px'
                                  },
                                  '& .MuiOutlinedInput-input': {
                                    padding: '4px 8px'
                                  },
                                  '& fieldset': {
                                    borderColor: 'rgba(0, 0, 0, 0.12)'
                                  }
                                }}
                              />
                            ) : (
                              <span style={{ display: 'inline-block', minWidth: '70px', textAlign: 'right' }}>
                                {row.strains[strain.strain_name] 
                                  ? parseFloat(row.strains[strain.strain_name]).toFixed(2) 
                                  : '0.00'
                              }
                              </span>
                            )}
                          </TableCell>
                        ))}
                        <TableCell 
                          align="right"
                          sx={{ 
                            padding: '4px',
                            width: '70px'
                          }}
                        >
                          {editingRow === row.farm_id ? (
                            <>
                              {hasChanges && (
                                <IconButton 
                                  color="primary" 
                                  onClick={() => handleSave(row.farm_id)}
                                  size="small"
                                  sx={{ 
                                    padding: '4px',
                                    marginRight: '4px'
                                  }}
                                >
                                  <SaveIcon sx={{ fontSize: '1.2rem' }} />
                                </IconButton>
                              )}
                              <IconButton 
                                color="error" 
                                onClick={handleCancelEdit}
                                size="small"
                                sx={{ padding: '4px' }}
                              >
                                <CloseIcon sx={{ fontSize: '1.2rem' }} />
                              </IconButton>
                            </>
                          ) : (
                            <IconButton 
                              onClick={() => handleEdit(row.farm_id)}
                              size="small"
                              sx={{ padding: '4px' }}
                            >
                              <EditIcon sx={{ fontSize: '1.2rem' }} />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>

        {/* Add Record Shrinkage Button */}
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={handleOpenShrinkageDialog}
          >
            Record Shrinkage
          </Button>
        </Box>

        {/* Section Divider */}
        <Box 
          display="flex" 
          alignItems="center" 
          justifyContent="center" 
          width="100%"
          sx={{ my: 4 }}
        >
          <Divider sx={{ width: '35%', borderColor: 'rgba(0, 0, 0, 0.12)' }} />
          <Typography 
            variant="h6" 
            sx={{ 
              mx: 2,
              color: 'rgba(0, 0, 0, 0.6)',
              fontWeight: 400
            }}
          >
            Orders
          </Typography>
          <Divider sx={{ width: '35%', borderColor: 'rgba(0, 0, 0, 0.12)' }} />
        </Box>

        {/* Orders Section */}
        <Box>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12}>
              <Box sx={{ mb: 3 }}>
                {/* Week Navigation */}
                <Box 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    mb: 3,
                    gap: 2
                  }}
                >
                  <IconButton onClick={goToPreviousWeek}>
                    <NavigateBeforeIcon />
                  </IconButton>
                  
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    gap: 1
                  }}>
                    <Typography variant="h6">
                      {formatDateRange(currentDate)}
                    </Typography>
                    <IconButton 
                      size="small" 
                      onClick={goToCurrentWeek}
                      title="Go to current week"
                    >
                      <TodayIcon />
                    </IconButton>
                  </Box>

                  <IconButton onClick={goToNextWeek}>
                    <NavigateNextIcon />
                  </IconButton>
                </Box>

                {/* Weekly Orders Table */}
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Fulfillment Date</TableCell>
                        <TableCell>Customer</TableCell>
                        <TableCell>Farm</TableCell>
                        <TableCell>Order Items</TableCell>
                        <TableCell align="center">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {weeklyOrders
                        .sort((a, b) => {
                          // Handle null fulfillment dates
                          if (!a.fulfillment_date && !b.fulfillment_date) return 0;
                          if (!a.fulfillment_date) return 1;  // Null dates go to the end
                          if (!b.fulfillment_date) return -1;
                          
                          // Compare fulfillment dates
                          return new Date(a.fulfillment_date) - new Date(b.fulfillment_date);
                        })
                        .map((order) => (
                          <TableRow key={order.order_id}>
                            <TableCell>{formatDateWithDay(order.fulfillment_date)}</TableCell>
                            <TableCell>{order.customer_name}</TableCell>
                            <TableCell>{order.farm_name}</TableCell>
                            <TableCell>
                              <Stack spacing={0.5}>
                                {(typeof order.items === 'string' ? JSON.parse(order.items) : order.items).map((item, index) => (
                                  <Box 
                                    key={index}
                                    sx={{ 
                                      display: 'flex', 
                                      gap: 1,
                                      alignItems: 'center',
                                      '&:not(:last-child)': {
                                        borderBottom: '1px solid',
                                        borderColor: 'divider',
                                        pb: 0.5
                                      }
                                    }}
                                  >
                                    <Checkbox
                                      size="small"
                                      checked={Boolean(item.is_completed)}
                                      onChange={(e) => handleItemCompletion(order.order_id, index, e.target.checked)}
                                      sx={{ p: 0.5 }}
                                    />
                                    <Typography 
                                      variant="body2" 
                                      sx={{ 
                                        flexGrow: 1,
                                        textDecoration: item.is_completed ? 'line-through' : 'none',
                                        color: item.is_completed ? 'text.secondary' : 'text.primary'
                                      }}
                                    >
                                      {item.strain_name}
                                    </Typography>
                                    <Typography 
                                      variant="body2" 
                                      color="text.secondary"
                                      sx={{
                                        textDecoration: item.is_completed ? 'line-through' : 'none'
                                      }}
                                    >
                                      {Number(item.weight).toFixed(2)}
                                    </Typography>
                                  </Box>
                                ))}
                              </Stack>
                            </TableCell>
                            <TableCell align="center">
                              <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
                                <IconButton 
                                  size="small"
                                  onClick={() => handleEditOrder(order)}
                                >
                                  <EditIcon />
                                </IconButton>
                                <IconButton 
                                  size="small"
                                  color="error"
                                  onClick={() => handleDeleteOrder(order.order_id)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* No Orders Message */}
                {weeklyOrders.length === 0 && (
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    p: 3 
                  }}>
                    <Typography color="text.secondary">
                      No orders for this week
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* Add Order FAB */}
        <Fab 
          color="primary" 
          sx={{ position: 'fixed', bottom: 16, right: 16 }}
          onClick={() => setOpenOrderDialog(true)}
        >
          <AddIcon />
        </Fab>

        {/* Order Dialog */}
        <Dialog 
          open={openOrderDialog} 
          onClose={handleCloseOrderDialog} 
          maxWidth="md" 
          fullWidth
        >
          <DialogTitle>
            {editMode ? 'Update Order' : 'Create New Order'}
          </DialogTitle>
          <DialogContent>
            <Stack spacing={3} sx={{ mt: 2 }}>
              {/* Customer Selection */}
              <FormControl fullWidth size="small">
                <InputLabel>Customer</InputLabel>
                <Select
                  value={selectedCustomer ? selectedCustomer.customer_id : ''}
                  onChange={(e) => {
                    const customer = customers.find(c => c.customer_id === e.target.value);
                    setSelectedCustomer(customer);
                  }}
                  label="Customer"
                >
                  {customers.map((customer) => (
                    <MenuItem key={customer.customer_id} value={customer.customer_id}>
                      {customer.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Farm Selection */}
              <FormControl fullWidth size="small">
                <InputLabel>Farm</InputLabel>
                <Select
                  value={selectedFarm || ''}
                  onChange={handleFarmChange}
                  label="Farm"
                >
                  {farms.map((farm) => (
                    <MenuItem key={farm.farm_id} value={farm.farm_id}>
                      {farm.farm_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Order Items Section */}
              <Box sx={{ border: 1, borderColor: 'divider', p: 2, borderRadius: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography variant="subtitle1">Order Items</Typography>
                  <Button
                    startIcon={<AddIcon />}
                    size="small"
                    onClick={handleAddOrderItem}
                  >
                    Add Item
                  </Button>
                </Box>
                
                {orderItems.map((item, index) => (
                  <Box 
                    key={index} 
                    sx={{ 
                      display: 'flex', 
                      gap: 2, 
                      mb: 2, 
                      alignItems: 'center',
                      backgroundColor: 'background.paper',
                      p: 1,
                      borderRadius: 1
                    }}
                  >
                    <FormControl size="small" sx={{ flexGrow: 1 }}>
                      <InputLabel>Strain</InputLabel>
                      <Select
                        value={item.strain_id || ''}
                        onChange={(e) => handleOrderItemChange(index, 'strain_id', e.target.value)}
                        label="Strain"
                      >
                        {Array.from(strains).map((strain) => (
                          <MenuItem key={strain.strain_id} value={strain.strain_id}>
                            {strain.strain_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <TextField
                      size="small"
                      label="Weight"
                      type="number"
                      value={item.weight}
                      onChange={(e) => handleOrderItemChange(index, 'weight', e.target.value)}
                      inputProps={{ min: 0, step: 0.01 }}
                      sx={{ width: '120px' }}
                    />

                    <IconButton 
                      size="small"
                      color="error"
                      onClick={() => handleRemoveOrderItem(index)}
                      disabled={orderItems.length === 1}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}
              </Box>

              {/* Date Selection - only fulfillment date */}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Fulfillment Date"
                  value={fulfillmentDate}
                  onChange={(newValue) => setFulfillmentDate(newValue)}
                  renderInput={(params) => (
                    <TextField 
                      {...params} 
                      size="small" 
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseOrderDialog}>Cancel</Button>
            <Button 
              onClick={handleCreateOrder}
              variant="contained"
              disabled={
                !selectedCustomer || 
                !selectedFarm || 
                orderItems.some(item => !item.strain_id || !item.weight)
              }
            >
              {editMode ? 'Update Order' : 'Create Order'}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Shrinkage Dialog */}
        <Dialog 
          open={openShrinkageDialog} 
          onClose={handleCloseShrinkageDialog}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Record Shrinkage</DialogTitle>
          <DialogContent>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Strain</TableCell>
                    <TableCell align="right">Current Weight</TableCell>
                    <TableCell align="right">New Weight</TableCell>
                    <TableCell align="right">Shrinkage</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {shrinkageData.map((item) => {
                    const shrinkage = item.new_weight 
                      ? (parseFloat(item.current_weight) - parseFloat(item.new_weight)).toFixed(2)
                      : '0.00';
                    const isNegativeShrinkage = item.new_weight && parseFloat(item.new_weight) > parseFloat(item.current_weight);

                    return (
                      <TableRow key={item.strain_id}>
                        <TableCell>{item.strain_name}</TableCell>
                        <TableCell align="right">
                          {parseFloat(item.current_weight).toFixed(2)}
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            type="number"
                            size="small"
                            value={item.new_weight}
                            onChange={(e) => handleShrinkageWeightChange(item.strain_id, e.target.value)}
                            error={isNegativeShrinkage}
                            inputProps={{ 
                              step: 0.01,
                              min: 0,
                              style: { textAlign: 'right' }
                            }}
                            sx={{ width: '100px' }}
                          />
                        </TableCell>
                        <TableCell 
                          align="right"
                          sx={{ 
                            color: parseFloat(shrinkage) > 0 ? 'error.main' : 'inherit',
                            fontWeight: parseFloat(shrinkage) > 0 ? 'bold' : 'normal'
                          }}
                        >
                          {shrinkage}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseShrinkageDialog}>Cancel</Button>
            <Button 
              onClick={handleSaveShrinkage} 
              color="primary"
              disabled={!shrinkageData.some(item => 
                item.new_weight && 
                parseFloat(item.new_weight) < parseFloat(item.current_weight)
              )}
            >
              Record Shrinkage
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </Box>
  );
}

export default CustomerOrders; 